import axios from 'axios'
import { baseApiUrl } from '@/global'

export default {
    state: {

    },
    getters: {

    },
    mutations: {
        whatsapp(state, message) {
            for(let i = 0; message.length > i; i++) {
                //Trata o N° para ligação
                let string1 = message[i].phone.slice(1, 3)
                let string2 = message[i].phone.slice(5, 9)
                let string3 = message[i].phone.slice(10, 14)
                let number = "55" + string1 + string2 + string3
                //envia a msg
                axios.post(`${baseApiUrl}/whatsappmessage`, {
                    text: message.text,
                    number
                }).then(res => {
                    
                })
            }
        },
        
    },
    actions: {},
}

