import axios from 'axios'
import { userKey } from '@/global'
export default {
  state: {
    user: null,
  },
  getters: {
    user: state => state.user
  },
  mutations: {
    setUser(state, user) {
      state.user = user
      if (user) {
        axios.defaults.headers.common['Authorization'] = `bearer ${user.token}`
      } else {
        delete axios.defaults.headers.common['Authorization']
      }
    },
    setProduct(state, product) {
      const json = localStorage.getItem(userKey)
      let userData = JSON.parse(json)
      userData.produto = product
      localStorage.setItem(userKey, JSON.stringify(userData))
    }

  },
  actions: {
    returnUser({commit}) {
      commit("setUser")
    }
  },
}
