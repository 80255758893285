import Vue from 'vue'
import VueRouter from 'vue-router'
import { baseApiUrl, userKey } from "@/global";

Vue.use(VueRouter)

const router = new VueRouter({
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        requiresAccess: false,
        layout: 'full',
      },
    },
    {
      path: '/login',

      component: () => import('@/views/Login.vue'),
      meta: {
        requiresAccess: false,
        layout: 'full',
      },
    },
    {
      path: '/cadastrar',
      name: 'Cadastrar',
      component: () => import('@/views/cadastrar/Cadastrar.vue'),
      meta: {
        requiresAccess: false,
        layout: 'full',
      },
    },
    {
      path: '/cadastrar/:id',
      name: 'Cadastrar',
      component: () => import('@/views/cadastrar/Cadastrar.vue'),
      meta: {
        requiresAccess: false,
        layout: 'full',
      },
    },
    {
      path: '/cadastrando/:id',
      name: 'Cadastrar',
      component: () => import('@/views/cadastrar/components/Form.vue'),
      meta: {
        requiresAccess: false,
        layout: 'full',
      },
    },
    {
      path: '/treinamentos',
      name: 'Treinamento',
      component: () => import('@/views/treinamentos/Treinamento.vue'),
      meta: {
        requiresAccess: false,
        layout: 'full',
      },
    },
    {
      path: '/treinamentos/:id',
      name: 'Player',
      component: () => import('@/views/treinamentos/Player.vue'),
      meta: {
        requiresAccess: false,
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-401',
      name: 'error-401',
      component: () => import('@/views/error/Error401.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/pesquisa',
      name: 'Pesquisa',
      component: () => import('@/views/pesquisas/Pesquisa.vue'),
      meta: {
        requiresAccess: false,
        layout: 'full',
        pageTitle: 'Pesquisa',
        breadcrumb: [
          {
            text: 'Pesquisa',
            active: true,
          },
        ],
      },
    },
    {
      path: '/pesquisa/:id',
      name: 'Pesquisa',
      component: () => import('@/views/pesquisas/Pesquisa.vue'),
      meta: {
        requiresAccess: false,
        layout: 'full',
        pageTitle: 'Pesquisa',
        breadcrumb: [
          {
            text: 'Pesquisa',
            active: true,
          },
        ],
      },
    },
    {
      path: '/teste',
      name: 'Teste',
      component: () => import('@/views/teste/Teste.vue'),
      meta: {
        requiresAccess: true,
        id: 0,

      },
    },
    {
      path: '/home',
      name: 'DashBoard',
      component: () => import('@/views/dashboard/DashBoard.vue'),
      meta: {
        requiresAccess: true,
        id: 0,
        pageTitle: 'DashBoard',
        breadcrumb: [
          {
            text: 'Dashboard',
            active: true,
          },
        ],
      },
    },
    {
      path: '/usuarios',
      name: 'Usuários',
      component: () => import('@/views/usuarios/Users.vue'),
      meta: {
        id: 1,
        requiresAccess: true,
        pageTitle: 'Usuários',
        breadcrumb: [
          {
            text: 'Usuários',
            active: true,
          },
        ],
      },
    },
    {
      path: '/usuarios/:id',
      name: 'Usuários',
      component: () => import('@/views/usuarios/perfil/UserPerfil.vue'),
      meta: {
        requiresAccess: true,
        id: 1,
        pageTitle: 'Usuários',
        breadcrumb: [
          {
            text: "Usuários",
            to: { name: "Usuários" }
          },
          {
            text: 'Perfil de Usuário',
            active: true,
          },
        ],
      },
    },
    {
      path: '/agenda',
      name: 'Agenda',
      component: () => import('@/views/agenda/Agenda.vue'),
      meta: {
        requiresAccess: true,
        id: 2,
        pageTitle: 'Cadastros',
        breadcrumb: [
          {
            text: 'Cadastros',
            active: true,
          },
        ],
      },
    },
    {
      path: '/produto',
      name: 'Produto',
      component: () => import('@/views/produto/Produto.vue'),
      meta: {
        requiresAccess: true,
        id: 3,
        pageTitle: 'Produtos',
        breadcrumb: [
          {
            text: 'Produtos',
            active: true,
          },
        ],
      },
    },
    {
      path: '/produto/:id',
      name: 'Produto',
      component: () => import('@/views/produto//perfil/ProdutoPerfil.vue'),
      meta: {
        requiresAccess: true,
        id: 3,
        pageTitle: 'Produtos',
        breadcrumb: [
          {
            text: 'Produtos',
            active: true,
          },
        ],
      },
    },
    {
      path: '/cadastro',
      name: 'Cadastros',
      component: () => import('@/views/cadastros/Cadastros.vue'),
      meta: {
        requiresAccess: true,
        id: 4,
        pageTitle: 'Cadastros',
        breadcrumb: [
          {
            text: 'Cadastros',
            active: true,
            to: { name: 'Cadastros' }
          },
        ],
      },
    },
    {
      path: '/cadastros/:id',
      name: 'Cadastros',
      component: () => import('@/views/cadastros/perfil/CadastroView.vue'),
      meta: {
        requiresAccess: true,
        id: 4,
        pageTitle: 'Cadastros',
        to: { name: 'Cadastros' },
        breadcrumb: [
          {
            text: "Cadastros",
            to: { name: 'Cadastros' }
          },
          {
            text: 'Perfil',
            active: true,
          },
        ],
      },
    },
    {
      path: '/pontos',
      name: 'Pontos',
      component: () => import('@/views/Pontos/Pontos.vue'),
      meta: {
        requiresAccess: true,
        id: 5,
        pageTitle: 'Pontos',
        breadcrumb: [
          {
            text: 'Pontos',
            active: true,

          },
        ],
      },
    },
    {
      path: '/pontos/:id',
      name: 'Pontos',
      component: () => import('@/views/Pontos/perfil/Perfil.vue'),
      meta: {
        requiresAccess: true,
        id: 5,
        pageTitle: 'Pontos',
        breadcrumb: [
          {
            text: 'Pontos',
            active: true,

          },
        ],
      },
    },
    {
      path: '/vendas',
      name: 'Vendas',
      component: () => import('@/views/vendas/Vendas.vue'),
      meta: {
        requiresAccess: true,
        id: 6,
        pageTitle: 'Vendas',
        breadcrumb: [
          {
            text: 'Vendas',
            active: true,

          },
        ],
      },
    },
    {
      path: '/vendas/:id',
      name: 'Vendas',
      component: () => import('@/views/vendas/perfil/Perfil.vue'),
      meta: {
        requiresAccess: true,
        id: 6,
        pageTitle: 'Vendas',
        breadcrumb: [
          {
            text: 'Vendas',
            active: true,

          },
        ],
      },
    },
    {
      path: '/atendimento',
      name: 'Atendimento',
      component: () => import('@/views/atendimento/Main.vue'),
      meta: {
        requiresAccess: true,
        id: 7,
        pageTitle: 'Atendimento',
        breadcrumb: [
          {
            text: 'Atendimento',
            active: true,

          },
        ],
      },
    },
    {
      path: '/configuracao',
      name: 'Configuração',
      component: () => import('@/views/configuracao/Configuracao.vue'),
      meta: {
        requiresAccess: true,
        id: 0,
        pageTitle: 'Configurações',
        breadcrumb: [
          {
            text: 'Configurações',
            active: true,
          },
        ],
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})


// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

import axios from 'axios';
import store from "@/store";
import user from '@/store/user';

router.beforeEach(async (to, from, next) => {
  if (!to.meta.requiresAccess) {
    next()
    return
  }
  const json = localStorage.getItem(userKey)
  const userData = JSON.parse(json)
  if (!userData) {
    next({ path: '/' })
    return
  }
  const permision = userData.menu.includes(`${to.meta.id}`)
  if (!permision) {
    localStorage.removeItem(userKey)
    next({ path: '/' })
    return
  }
  const res = await axios.post(`${baseApiUrl}/validatetoken`, userData)
  if (res.data) {
    store.commit("setUser", userData)
    next()
    return
  } else {
    localStorage.removeItem(userKey)
    next({ path: '/' })
  }
})



export default router
